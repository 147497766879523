.notifications-page {
  padding: 6rem 1rem 1rem;
  min-height: 100vh;
  background: var(--background-gradient);
}

.notifications-container {
  background: white;
  border-radius: 20px;
  padding: 0px;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} 