.feedback-page {
  min-height: 100vh;
  padding: 1rem;
  position: relative;
}

.feedback-container {
  margin-top: 100px;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.feedback-container h2 {
  text-align: left;
  margin-bottom: 2rem;
  color: var(--text-primary);
}

textarea {
  width: 100%;
  min-height: 150px;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 12px;
  margin-bottom: 1rem;
  font-family: inherit;
  font-size: 16px;
  resize: vertical;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background: #3db600;
  color: white;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submit-button:hover {
  background: #303030;
}

.feedback-description {
  text-align: left;
  color: #666;
  margin-bottom: 2rem;
  line-height: 1.5;
  font-size: 0.95rem;
}

.email-input {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 12px;
  margin-bottom: 1rem;
  font-family: inherit;
  font-size: 16px;
}

.email-input:focus {
  outline: none;
  border-color: #3db600;
}

.feedback-form button {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 12px;
  background: #3db600;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Style pour le bouton désactivé */
.feedback-form button.disabled {
  background: #f0f0f0;
  color: #666;
  cursor: not-allowed;
  animation: pulse 2s infinite;
}

/* Animation de pulsation pour le bouton désactivé */
@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

/* Message de succès */
.feedback-success {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #4CAF50;
  color: white;
  padding: 1rem 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  animation: slideUp 0.3s ease;
  z-index: 1000;
}

@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

/* Responsive */
@media (max-width: 768px) {
  .feedback-container {
    margin-top: 100px;
    padding: 1.5rem;
  }
  
  .feedback-success {
    width: 90%;
    text-align: center;
  }
}