.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  display: flex;
  justify-content: space-around;
  padding: 1rem 0.5rem;
  z-index: 1000;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
}

.nav-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: 0.8rem;
  padding: 0.5rem;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.nav-item.active {
  color: #3db600;
}

.nav-item svg {
  width: 30px;
  height: 30px;
  margin-bottom: 6px;
  fill: white;
}

.nav-item.active svg {
  fill: #3db600;
}

.nav-item span {
  margin-top: 2px;
}

.nav-item:focus-visible {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

.nav-item-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notification-dot {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 12px;
  height: 12px;
  background-color: #3db600;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.7);
  z-index: 2000;
  box-shadow: 0 0 8px rgba(61, 182, 0, 0.6);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 8px rgba(61, 182, 0, 0.6);
  }
  50% {
    box-shadow: 0 0 12px rgba(61, 182, 0, 0.8);
  }
  100% {
    box-shadow: 0 0 8px rgba(61, 182, 0, 0.6);
  }
} 