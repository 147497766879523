.news-cards-container {
  position: fixed;
  top: 50px; /* Réduit de 60px à 50px pour remonter le conteneur */
  left: 0;
  right: 0;
  bottom: 70px; /* Hauteur de la navigation */
  z-index: 1; /* Ajout d'un z-index bas pour être sous les autres éléments */
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.news-cards-container::-webkit-scrollbar {
  display: none;
}

.card-news {
  scroll-snap-align: center;
  width: calc(100vh - 140px);
  max-width: calc(100% - 1rem);
  height: calc(100vh - 200px);
  border-radius: 20px;
  background: white;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);  /* Ombrage plus prononcé */
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  aspect-ratio: 9/16;
}

/* Reste des styles pour le contenu de la card */
.card-news__image-container {
  position: absolute; 
  bottom: 25px;
  left: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 9/16;  /* Maintient le ratio */
}

.card-news__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: scale(0.6);
  opacity: 0;  /* L'image commence invisible */
  transition: opacity 0.5s ease, transform 0.8s ease-out;  /* Transition douce */
  filter: blur(10px);
}

.card-news__image.loaded {
  opacity: 1;  /* L'image devient visible progressivement */
  transform: scale(0.9);
  filter: blur(0);
}

@keyframes imageReveal {
  0% {
    opacity: 0;
    transform: scale(0.3);
    filter: blur(50px);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
  }
}

.card-news__content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40%;  /* Augmenté de 30% à 40% */
  padding: 1rem 1.5rem;
  background: linear-gradient(
    to top, 
    rgba(32, 31, 31, 0.65) 0%,
    rgba(0, 0, 0, 0.5) 25%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;  /* Centre verticalement */
  gap: 0.3rem;
}

/* Ajout d'un léger flou pour améliorer la lisibilité */
.card-news__content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: -1;
  opacity: 0.3;
}

.card-news__title {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.3;
}

/* Le placeholder disparaît en fondu */
.card-news__image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f0f0f0;  /* Couleur de fond pendant le chargement */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.card-news__image.loaded + .card-news__image-placeholder {
  opacity: 0;
}

.card-news__image-loading {
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.card-news__category-tag {
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 8px 16px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  z-index: 2;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.card-news__button {
  position: absolute;  /* Position absolue */
  bottom: 1rem;       /* Distance fixe du bas */
  left: 1rem;       /* Aligné avec le padding du content */
  padding: 10px 16px;
  background: rgba(0, 0, 0, 0.4);
  border: none;
  border-radius: 15px;
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.card-news__button:hover {
  background: rgba(0, 0, 0, 0.7);
  transform: translateY(-2px);
}

.card-news__button:active {
  transform: translateY(0);
}

.card-news__description {
   /* Marge en bas pour le bouton */
  font-size: 0.9rem;
  line-height: 1.4;
  opacity: 0.9;
  display: -webkit-box;
  -webkit-line-clamp: 2;  /* Limite à 2 lignes */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-news__badge {
  position: absolute;
  top: 1rem;          /* Même hauteur que la catégorie */
  right: 1rem;
  padding: 8px 16px;
  background: #3db600;
  color: white;
  border-radius: 20px;
  font-size: 0.8rem;  /* Même taille que la catégorie */
  font-weight: 600;
  letter-spacing: 0.8px;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.news-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  color: #666;
  gap: 1rem;
}

.news-empty-state p:first-child {
  font-size: 2rem;
  font-weight: 500;
}

.news-empty-state p:last-child {
  font-size: 1.5rem;
}
