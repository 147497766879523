:root {
  /* Couleurs principales */
  --primary-color: #1a1a1a;     /* Noir mat pour le header */
  --primary-dark: #000000;      /* Noir pur pour hover */
  --primary-light: #2d2d2d;     /* Noir plus clair */

  /* Couleurs secondaires */
  --secondary-color: #4b5563;   /* Gris */
  --secondary-light: #9ca3af;   /* Gris clair */
  --secondary-dark: #1f2937;    /* Gris foncé */

  /* Couleurs de fond */
  --background-primary: #f5f5f5;    /* Blanc cassé */
  --background-secondary: #e0e7e3;   /* Vert très clair */
  --background-gradient: linear-gradient(135deg, var(--background-primary) 0%, var(--background-secondary) 100%);

  /* Couleurs de navigation */
  --nav-background: rgba(26, 26, 26, 0.95);  /* Noir mat avec transparence */
  --nav-border: rgba(255, 255, 255, 0.1);
  --nav-active: #4CAF50;               /* Vert pour l'élément actif */

  /* Couleurs de texte */
  --text-primary: #1a1a1a;      /* Texte principal noir mat */
  --text-secondary: #666666;    /* Texte secondaire gris */
  --text-light: #ffffff;        /* Texte clair pour dark bg */
  
  /* Couleurs d'état */
  --success: #4CAF50;           /* Vert pour succès */
  --error: #f44336;             /* Rouge pour erreur */
  --warning: #ff9800;           /* Orange pour avertissement */
  
  /* Effets */
  --glass-blur: blur(10px);
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
  
  /* Transitions */
  --transition-speed: 0.3s;
  --transition-ease: cubic-bezier(0.4, 0, 0.2, 1);
  
  /* Espacements */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  
  /* Border radius */
  --radius-sm: 0.25rem;
  --radius-md: 0.5rem;
  --radius-lg: 1rem;
  --radius-full: 9999px;
} 