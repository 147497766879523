.ios-install-prompt {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 1rem;
  backdrop-filter: blur(5px);
}

.ios-install-content {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  max-width: 500px;
  width: 94%;
  text-align: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.app-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.install-header {
  margin-bottom: 2rem;
  padding: 0 1rem;
}

.install-header h2 {
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  color: var(--text-primary);
  font-weight: 600;
}

.install-header p {
  color: var(--text-secondary);
  font-size: 1rem;
  line-height: 1.4;
}

.install-steps {
  text-align: left;
  padding: 0 1.5rem;
  position: relative;
  margin: 0 -1rem;
}

.step {
  margin: 0.6rem 0;
  padding: 0.875rem 1.75rem;
  background: var(--background-primary);
  border-radius: 12px;
  transition: transform 0.2s ease;
  width: 100%;
  position: relative;
}

.step:hover {
  transform: translateY(-2px);
}

.step-header {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: var(--text-primary);
  line-height: 1.2;
  width: 100%;
}

.step-number {
  background: var(--primary-color);
  color: white;
  min-width: 22px;
  min-height: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 600;
  flex-shrink: 0;
  aspect-ratio: 1;
  position: absolute;
  left: -1.75rem;
  top: 50%;
  transform: translateY(-50%);
}

.step-text-container {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  white-space: nowrap;
}

.step-header > span:not(.step-number) {
  display: inline-flex;
  align-items: center;
}

.ios-icon {
  height: 18px;
  width: auto;
  margin: 0 3px;
  vertical-align: middle;
  object-fit: contain;
  flex-shrink: 0;
}

@media (max-width: 380px) {
  .ios-install-content {
    padding: 1.25rem;
    width: 96%;
  }

  .step {
    padding: 0.75rem 1.5rem;
    margin: 0.6rem 0;
  }

  .step-header {
    font-size: 0.8rem;
  }

  .step-number {
    min-width: 20px;
    min-height: 20px;
    left: -1.5rem;
    font-size: 0.75rem;
  }

  .ios-icon {
    height: 16px;
  }

  .install-steps {
    padding: 0 1.25rem;
  }

  .step-text-container {
    gap: 3px;
  }
} 