.news-page {
  width: 100%;
  height: 100vh;
}

.news-cards-container {
  position: fixed;
  top: 50px;  /* Directement sous le header */
  left: 0;
  right: 0;
  bottom: 70px;
  z-index: 1;
}

@media (max-width: 768px) {
  .news-cards-container {
    padding-top: 6rem;
    gap: 1.5rem;
    padding-bottom: calc(50vh - 320px);
  }
  
  .card-news {
    margin: 0 0.8rem;
  }
}