.page-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
}

.header-title {
  padding: 1.3rem;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.notification-icon {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.notification-icon svg {
  width: 24px;
  height: 24px;
  color: #666;
}

.notification-icon:focus-visible {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

.header-icons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.feedback-icon {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.feedback-icon svg {
  width: 24px;
  height: 24px;
  color: #666;
}

.feedback-icon:focus-visible {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

.feedback-icon.active svg,
.notification-icon.active svg {
  color: #3db600;
} 