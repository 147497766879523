.deals-page {
  width: 100%;
  height: 100vh;
}

.deals-cards-container {
  position: fixed;
  top: 50px;  /* Directement sous le header */
  left: 0;
  right: 0;
  bottom: 70px;
  z-index: 1;
}

@media (max-width: 768px) {
  .deals-cards-container {
    
    gap: 1.5rem;
    padding-bottom: calc(50vh - 120px);
  }
  
  .card-deals {
    margin: 0 0.8rem;
  }
}

/* Ajuster le padding du conteneur de cards */
