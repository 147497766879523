.filter-button-container {
  position: fixed;
  bottom: 110px;
  right: 0.3rem;
  z-index: 1002;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
}

.filter-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #3db600;
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-tap-highlight-color: transparent;
}

.filter-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.filter-button svg {
  width: 22px;
  height: 22px;
  color: white;
}

.filter-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1010;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.2s ease;
}

.modal-content {
  background: white;
  width: 90%;
  max-width: 400px;
  border-radius: 20px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transform-origin: bottom right;
  animation: modalOpen 0.2s ease;
}

.modal-content h3 {
  margin: 0;
  color: #333;
  font-size: 1.2rem;
}

.filter-section {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.filter-section h4 {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
}

.buttons-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 0.5rem;
}

.filter-option {
  padding: 0.5rem;
  border-radius: 12px;
  border: none;
  background: #f0f2f5;
  color: #666;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-option:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.filter-option.active {
  background: #3db600;
  color: white;
}

.close-button {
  margin-top: 1rem;
  padding: 0.8rem;
  border: none;
  border-radius: 12px;
  background: #f0f2f5;
  color: #666;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.close-button:hover {
  background: #e4e6e9;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes modalOpen {
  from { 
    transform: scale(0.8);
    opacity: 0;
  }
  to { 
    transform: scale(1);
    opacity: 1;
  }
} 