.shops-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.shop-marker-icon {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

.shop-popup-content {
  padding: 0.5rem;
  min-width: 200px;
}

.shop-popup-content h3 {
  margin: 0 0 0.75rem 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #1a1a1a;
}

.shop-popup-content p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
  font-size: 0.9rem;
  color: #4b5563;
}

.popup-icon {
  width: 18px;
  height: 18px;
  color: #3db600;
}

/* Style de la popup Leaflet */
.leaflet-popup-content-wrapper {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.leaflet-popup-content {
  margin: 0.5rem;
}

.leaflet-popup-tip-container {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.shop-popup {
  padding: 0.5rem;
}

.shop-popup h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  font-weight: 600;
}

.shop-popup p {
  margin: 0.25rem 0;
  font-size: 0.9rem;
}

/* Style pour le thème sombre */
.dark-theme .leaflet-tile {
  filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
}

/* Personnalisation des contrôles de zoom */
.leaflet-control-zoom {
  border: none !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1) !important;
}

.leaflet-control-zoom a {
  background-color: white !important;
  color: #666 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.leaflet-control-zoom a:hover {
  background-color: #f8f9fa !important;
  color: #333 !important;
}

/* Pour s'assurer que le conteneur de la carte prend tout l'espace disponible */
.leaflet-container {
  width: 100%;
  height: 100vh;
} 