.deals-cards-container {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 70px;
  z-index: 1;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.deals-cards-container::-webkit-scrollbar {
  display: none;
}

.card-deals {
  scroll-snap-align: center;
  width: calc(100vh - 140px);
  max-width: calc(100% - 1rem);
  height: calc(100vh - 200px);
  border-radius: 20px;
  background: white;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  aspect-ratio: 9/16;
}

.card-deals:hover {
  transform: none;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
}

.card-deals__category-tag {
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 8px 16px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  z-index: 2;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.card-deals__image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-deals__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.8s ease-out;
  filter: blur(50px);
}

.card-deals__image.loaded {
  opacity: 1;
  transform: scale(1);
  filter: blur(0);
}

.card-deals__image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.card-deals__image.loaded + .card-deals__image-placeholder {
  opacity: 0;
}

.card-deals__image-loading {
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.card-deals:hover .card-deals__image {
  transform: none;
}

.card-deals__content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40%;
  padding: 1rem 1.5rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.3rem;
}

.card-deals__content::before {
  display: none;
}

.card-deals__category {
  padding: 8px 16px;
  border-radius: 20px;
  background: #f0f2f5;
  color: #666;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

.card-deals__date {
  font-size: 0.9rem;
  color: var(--secondary-color);
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.card-deals__date::before {
  content: '';
  width: 20px;
  height: 2px;
  background-color: var(--primary-color);
  display: inline-block;
}

.card-deals__title {
  display: none;
}

.card-deals__description {
  font-size: 0.9rem;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
}

.card-deals__footer {
  margin-top: auto;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.card-deals__read-more {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-color);
  font-weight: 600;
  text-decoration: none;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  padding: 0.5rem 0;
}

.card-deals__read-more::after {
  content: '→';
  transition: transform 0.3s ease;
}

.card-deals__read-more:hover {
  color: var(--primary-dark);
  gap: 0.8rem;
}

.card-deals__read-more:hover::after {
  transform: translateX(4px);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(calc(50vh - 50% + 20px));
  }
  to {
    opacity: 1;
    transform: translateY(calc(50vh - 50%));
  }
}

.card-deals {
  animation: fadeInUp 0.6s ease forwards;
  animation-delay: calc(var(--animation-order) * 0.1s);
}

@media (max-width: 768px) {
  .deals-cards-container {
    gap: 1.5rem;
    padding-bottom: calc(50vh - 260px);
    margin: 2rem 1rem;
  }
  
  .card-deals {
    width: calc(100vh - 120px);
    max-width: calc(100% - 0.5rem);
  }
}

.deals-cards-container::after {
  content: '';
  position: fixed;
  bottom: 85px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 3px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 1.5px;
  pointer-events: none;
  animation: fadeInOut 2s infinite;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.3; }
  50% { opacity: 0.8; }
}

.card-deals__reduction-badge {
  position: absolute;
  top: 15px;
  right: 15px;
  background: linear-gradient(135deg, #FF4444, #FF6B6B);
  color: white;
  padding: 10px 15px;
  border-radius: 25px;
  font-weight: 700;
  font-size: 1.2rem;
  box-shadow: 0 4px 15px rgba(255, 68, 68, 0.3);
  z-index: 2;
  backdrop-filter: blur(5px);
}

.card-deals__tags {
  display: flex;
  gap: 8px;
  margin: 0;
  flex-wrap: wrap;
}

.card-deals__type {
  padding: 8px 16px;
  border-radius: 20px;
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.card-deals__validity {
  background: #f8f9fa;
  padding: 25px 30px;
  position: relative;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  gap: 15px;
}

.card-deals__validity::before {
  content: "🗓";
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-deals__validity span {
  color: #1a1a1a;
  font-size: 1.2rem;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.card-deals__validity span::before {
  content: "Période de validité";
  font-size: 0.85rem;
  color: #666;
  font-weight: 500;
}

.card-deals__conditions {
  background: linear-gradient(to bottom right, #f8f9fa, #ffffff);
  border-radius: 16px;
  padding: 30px;
  margin: 25px 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(61, 182, 0, 0.1);
  position: relative;
}

.card-deals__conditions h3 {
  color: #1a1a1a;
  font-size: 1.3rem;
  margin-bottom: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 15px;
  border-bottom: 2px solid rgba(61, 182, 0, 0.1);
}

.card-deals__conditions h3::before {
  content: "!";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: #3db600;
  color: white;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: 800;
  box-shadow: 0 4px 10px rgba(61, 182, 0, 0.2);
  transform: scale(1);
  transition: transform 0.3s ease;
}

.card-deals__conditions:hover h3::before {
  transform: scale(1.1);
}

.card-deals__conditions p {
  color: #4a4a4a;
  line-height: 1.7;
  font-size: 1.05rem;
  padding: 0 5px;
}

.card-deals__conditions::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background: #3db600;
  border-radius: 4px 0 0 4px;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-deals__conditions-button {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  padding: 10px 16px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 20px;
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.card-deals__conditions-button:hover {
  background: rgba(0, 0, 0, 0.7);
  transform: translateY(-2px);
}

.card-deals__conditions-button:active {
  transform: translateY(0);
}

/* Modal des conditions */
.conditions-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease, opacity 0.3s ease;
}

.conditions-modal {
  background: white;
  padding: 1.5rem;
  border-radius: 20px;
  width: 90%;
  max-width: 400px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.conditions-modal h3 {
  margin: 0;
  color: #333;
  font-size: 1.2rem;
}

.conditions-modal__content {
  overflow-y: auto;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 12px;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #666;
}

.conditions-modal__close {
  padding: 0.8rem;
  border: none;
  border-radius: 12px;
  background: #f0f2f5;
  color: #666;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.conditions-modal__close:hover {
  background: #e4e6e9;
}

.card-deals__button {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  padding: 10px 16px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 15px;
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.card-deals__button:hover {
  background: rgba(0, 0, 0, 0.7);
  transform: translateY(-2px);
}

.card-deals__button:active {
  transform: translateY(0);
}

.deals-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 2rem;
  color: #666;
  gap: 1rem;
}

.deals-empty-state p:first-child {
  font-size: 2rem;
  font-weight: 500;
}

.deals-empty-state p:last-child {
  font-size: 1.5rem;
}

.notification-hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  color: #3db600;
}

.notification-hint p {
  font-size: 0.9rem;
  font-weight: 500;
}

.arrow-up {
  font-size: 2rem;
  animation: bounceUpDown 2s infinite;
  margin-top: 0.5rem;
}

@keyframes bounceUpDown {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

/* Overlay */
.card-deals__modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.card-deals__modal-overlay.show {
  opacity: 1;
  visibility: visible;
}

/* Modal principal */
.card-deals__modal {
  background: white;
  border-radius: 24px;
  width: 90%;
  max-width: 500px;
  position: relative;
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.3s ease;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.card-deals__modal-overlay.show .card-deals__modal {
  transform: translateY(0);
  opacity: 1;
}

/* Bouton de fermeture */
.card-deals__modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  background: rgba(0, 0, 0, 0.1);
  color: #666;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 10;
}

.card-deals__modal-close:hover {
  background: rgba(0, 0, 0, 0.15);
  transform: rotate(90deg);
}

.card-deals__modal-close svg {
  width: 20px;
  height: 20px;
}

/* Contenu du modal */
.card-deals__modal-content {
  padding: 0;
}

/* Bouton d'action */
.card-deals__redirect {
  background: #3db600;
  color: white;
  width: calc(100% - 60px);
  padding: 18px;
  border: none;
  border-radius: 16px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0 30px 30px 30px;
}

.card-deals__redirect:hover {
  background: #34a000;
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(61, 182, 0, 0.2);
}

.card-deals__redirect:active {
  transform: translateY(0);
}

/* Ajout d'une icône au bouton */
.card-deals__redirect::after {
  content: "→";
  font-size: 1.2rem;
  transition: transform 0.2s ease;
}

.card-deals__redirect:hover::after {
  transform: translateX(5px);
}

/* Ajout d'une barre de défilement personnalisée pour les conditions */
.card-deals__conditions::-webkit-scrollbar {
  width: 8px;
}

.card-deals__conditions::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.card-deals__conditions::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 4px;
}

.card-deals__conditions::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

/* Style pour le conteneur des actions */
.card-deals__actions {
  margin: 25px 30px 30px 30px;
}

/* Style commun pour tous les boutons redirect dans les modals */
.card-deals__actions .card-deals__redirect {
  width: 100%;
  margin: 0;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #1a1a1a;
  font-weight: 600;
  font-size: 0.95rem;
  white-space: nowrap;
}

/* Style spécifique pour le bouton online */
.card-deals__actions .card-deals__redirect.online {
  border-color: #3db600;
  background: #f8f9fa;
  color: #1a1a1a;
}

.card-deals__actions .card-deals__redirect.online::before {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233db600'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-.61.08-1.21.21-1.78L8.99 15v1c0 1.1.9 2 2 2v1.93C7.06 19.43 4 16.07 4 12zm13.89 5.4c-.26-.81-1-1.4-1.9-1.4h-1v-3c0-.55-.45-1-1-1h-6v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41C17.92 5.77 20 8.65 20 12c0 2.08-.81 3.98-2.11 5.4z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Style spécifique pour le bouton store */
.card-deals__actions .card-deals__redirect.store {
  border-color: #2d8500;
  background: #f8f9fa;
  color: #1a1a1a;
}

.card-deals__actions .card-deals__redirect.store::before {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%232d8500'%3E%3Cpath d='M19 2H5C3.346 2 2 3.346 2 5v2.831c0 1.053.382 2.01 1 2.746V19c0 1.654 1.346 3 3 3h12c1.654 0 3-1.346 3-3v-8.424c.618-.735 1-1.692 1-2.746V5c0-1.654-1.346-3-3-3zm1 3v2.831c0 1.14-.849 2.112-1.891 2.167L18 10c-1.103 0-2-.897-2-2V4h3c.552 0 1 .449 1 1zM10 4h4v4c0 1.103-.897 2-2 2s-2-.897-2-2V4zM4 5c0-.551.448-1 1-1h3v4c0 1.103-.897 2-2 2l-.109-.003C4.849 9.943 4 8.971 4 7.831V5zm2 15c-.552 0-1-.449-1-1v-7.09c.322.066.658.09 1 .09 1.223 0 2.342-.417 3.249-1.109C9.999 11.518 10.942 12 12 12s2.001-.482 2.751-1.109C15.658 11.583 16.777 12 18 12c.342 0 .678-.024 1-.09V19c0 .551-.448 1-1 1H6z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Hover states commun pour tous les boutons */
.card-deals__actions .card-deals__redirect:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  background: #f8f9fa;
}

/* Suppression de la flèche pour tous les boutons */
.card-deals__actions .card-deals__redirect::after {
  content: none;
}

/* Message d'info pour le modal both sans conditions */
.card-deals__info-message {
  text-align: center;
  padding: 30px 20px;
}

.card-deals__info-message h3 {
  color: #1a1a1a;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.card-deals__info-message p {
  color: #666;
  font-size: 0.95rem;
  line-height: 1.5;
}

/* Style pour les boutons doubles */
.card-deals__actions.dual-buttons {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Ajoute un espace de 15px entre les boutons */
}

/* Pour s'assurer que les boutons gardent leur style */
.card-deals__actions.dual-buttons .card-deals__redirect {
  margin: 0; /* Reset la marge pour utiliser le gap */
}