.shop-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.shop-modal-content {
  background: white;
  border-radius: 15px;
  padding: 20px 20px 10px 20px;
  width: 90%;
  max-width: 500px;
  opacity: 0;
  transform: translateY(100%);
  animation: modalAppear 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  margin-bottom: 70px;
  touch-action: pan-y;
  user-select: none;
  position: relative;
}

@keyframes modalAppear {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.shop-modal-info h2 {
  margin: 0 0 1rem 0;
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
}

.shop-modal-detail {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  padding: 0.75rem;
  border-radius: 15px;
  background-color: #f5f5f5;
  max-width: 100%;
  cursor: pointer;
}

.shop-modal-detail.clickable {
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 15px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.shop-modal-detail:active {
  background-color: #e0e0e0;
  transform: scale(0.98);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.shop-modal-detail span {
  flex: 1;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-width: 0;
  line-height: 1.4;
  font-weight: bold;
  color: #333;
}

.modal-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
  color: #666;
  flex-shrink: 0;
}

.shop-modal-hours {
  margin-top: 1rem;
}

.hours-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.hours-toggle {
  font-size: 0.9rem;
  color: #666;
  margin-top: 0.25rem;
}

.chevron-icon {
  width: 1.25rem;
  height: 1.25rem;
  transition: transform 0.3s ease;
  margin-left: 0.5rem;
  color: #666;
}

.chevron-icon.expanded {
  transform: rotate(180deg);
}

.weekly-hours {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  opacity: 0;
}

.weekly-hours.expanded {
  max-height: 500px; /* Hauteur maximale suffisante */
  opacity: 1;
  transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
}

.day-hours {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 15px;
  margin: 4px 0;
  transition: background-color 0.2s ease;
}

.day-hours:last-child {
  border-bottom: none;
}

.day-label {
  font-weight: 500;
  color: #333;
}

.hours-label {
  color: #666;
  font-size: 0.9rem;
}

.shop-modal-detail.open {
  background-color: #e8f5e9;
}

.shop-modal-detail.closed {
  background-color: #ffebee;
}

.status-indicator {
  font-weight: 500;
}

.shop-modal-detail.open .status-indicator {
  color: #059669;
}

.shop-modal-detail.closed .status-indicator {
  color: #c62828;
}

.day-hours.holiday {
  background-color: #fff1f2;  /* Fond légèrement rouge pour les jours fériés */
}

.day-hours.holiday .hours-label {
  color: #dc2626;  /* Rouge pour le texte "Férié" */
  font-weight: 500;
}

.day-hours.special {
  background-color: #f0fdf4;  /* Fond légèrement vert pour les horaires spéciaux */
}

.day-hours.special .hours-label {
  color: #059669;  /* Vert pour les horaires spéciaux */
  font-weight: 500;
}

@media (max-width: 600px) {
  .shop-modal-content {
    width: 95%;
    padding: 1.5rem;
  }

  .shop-modal-info h2 {
    font-size: 1.25rem;
  }
}

.modal-drag-indicator {
  width: 36px;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  margin: 0 auto 20px;
}

.arrow-icon {
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  color: #666;
  transition: transform 0.2s ease;
}

.shop-modal-detail:active .arrow-icon {
  transform: translateX(3px);
}

.shop-modal-distance {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  color: #666;
  font-size: 0.9rem;
}

.shop-modal-distance .modal-icon {
  width: 16px;
  height: 16px;
  color: #3db600;
}

.shop-modal-distance span {
  font-weight: normal;
} 