.switch-container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 15px;
  background: white;
  border-radius: 12px;
  margin: 10px 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #3DB600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3DB600;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch-label {
  font-size: 16px;
  color: #333;
}

.switch-loading {
  color: #666;
  font-style: italic;
}

.slider.loading {
  opacity: 0.7;
  cursor: not-allowed;
}

.switch input:disabled + .slider {
  cursor: not-allowed;
  opacity: 0.7;
}

/* Animation de chargement */
@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.switch-loading {
  animation: pulse 1.5s infinite;
} 