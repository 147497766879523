.android-install-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 99999;
  overflow-y: auto;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.android-install-content {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.android-install-logo {
  width: 72px;
  height: 72px;
  margin-bottom: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.android-install-content h2 {
  font-size: clamp(1.25rem, 5vw, 1.5rem);
  margin-bottom: 0.75rem;
  color: #333;
  font-weight: 600;
  line-height: 1.3;
}

.android-install-content p {
  font-size: clamp(0.95rem, 4vw, 1.1rem);
  color: #666;
  margin-bottom: 2rem;
  line-height: 1.5;
  max-width: 320px;
}

.install-button {
  background: #3db600;
  color: white;
  border: none;
  padding: 0.875rem 2rem;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 12px rgba(61, 182, 0, 0.2);
}

.install-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(61, 182, 0, 0.25);
}

.install-button:disabled {
  background: #ccc;
  cursor: not-allowed;
  box-shadow: none;
}

/* Styles pour le message de succès */
.installation-success {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  text-align: center;
}

.installation-success h2 {
  font-size: clamp(1.25rem, 5vw, 1.5rem);
  margin-bottom: 0.75rem;
  color: #333;
}

.installation-success p {
  font-size: clamp(0.95rem, 4vw, 1.1rem);
  color: #666;
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.open-options {
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  margin-top: 2rem;
  width: 100%;
  max-width: 320px;
}

.open-option {
  background: #f5f5f5;
  padding: 1.25rem;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.2s ease;
}

.open-option:hover {
  background: #ebebeb;
  transform: translateY(-2px);
}

.open-option .icon {
  font-size: 1.25rem;
}

.open-option p {
  margin: 0;
  font-size: 0.95rem;
  color: #333;
  font-weight: 500;
}

/* Adaptation pour les petits écrans */
@media (max-height: 600px) {
  .android-install-content,
  .installation-success {
    padding: 1.5rem 1rem;
  }

  .android-install-logo {
    width: 60px;
    height: 60px;
    margin-bottom: 1.5rem;
  }

  .open-options {
    gap: 0.75rem;
    margin-top: 1.5rem;
  }

  .open-option {
    padding: 1rem;
  }
} 