.reinstall-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.reinstall-modal {
  background: white;
  padding: 20px;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
}

.reinstall-modal h2 {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.steps ol {
  padding-left: 20px;
}

.steps li {
  margin-bottom: 15px;
}

.steps ul {
  padding-left: 20px;
  margin-top: 10px;
}

.install-button {
  display: block;
  width: 100%;
  padding: 12px;
  background: #3DB600;
  color: white;
  border: none;
  border-radius: 8px;
  margin: 10px 0;
  font-weight: 500;
}

.close-button {
  width: 100%;
  padding: 12px;
  background: #eee;
  border: none;
  border-radius: 8px;
  margin-top: 20px;
  color: #333;
} 