body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.shops-page {
  width: 100%;
  height: 100vh;
}

.shops-container {
    position: relative;
    width: 100%;
    height: 100vh;
    padding-top: 60px; 
}

.shop-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.shop-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.shop-card h3 {
  margin: 0 0 1rem 0;
  color: #333;
  font-size: 1.5rem;
}

.shop-card p {
  margin: 0.5rem 0;
  color: #666;
}

.shop-status {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  font-size: 0.875rem;
  font-weight: 500;
}

.shop-status.open {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.shop-status.closed {
  background-color: #ffebee;
  color: #c62828;
}

.shop-card__image-container {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 24px 24px 0 0;
}

.shop-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.shop-card:hover .shop-card__image {
  transform: scale(1.05);
}

.shop-card__image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-card__image-loading {
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3db600;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.shop-card__image.loaded {
  opacity: 1;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.shop-card__content {
  padding: 1.5rem;
}

.shop-card__name {
  margin: 0 0 1.5rem 0;
  font-size: 1.5rem;
  font-weight: 700;
  color: #1a1a1a;
  letter-spacing: -0.5px;
}

.shop-card__address {
  color: var(--text-secondary);
  margin: 0.8rem 0;
  font-size: 1rem;
  line-height: 1.4;
}

.shop-card__phone {
  margin: 0.8rem 0;
}

.shop-card__phone a {
  color: #3db600;
  text-decoration: none;
  font-weight: 500;
}

.shop-card__phone,
.shop-card__address {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.25rem;
  margin: 1rem 0;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 16px;
  background: #f8f9fa;
}

.shop-card__phone:hover,
.shop-card__address:hover {
  background: #f0f2f5;
}

.phone-icon,
.location-icon {
  width: 24px;
  height: 24px;
  color: #1a1a1a;
}

.shop-card__phone span,
.shop-card__address span {
  font-size: 1rem;
  color: #1a1a1a;
  font-weight: 500;
}

.shop-card__hours {
  margin-top: 2rem;
}

.shop-card__hours h3 {
  font-size: 1.1rem;
  margin: 0 0 1rem 0;
  color: #1a1a1a;
  font-weight: 600;
}

.shop-card__hours-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem;
  border-radius: 16px;
  font-size: 1rem;
  background: #f8f9fa;
}

.shop-card__hours-text.open {
  background: #ecfdf3;
  color: #059669;
}

.shop-card__hours-text.closed {
  background: #fef2f2;
  color: #dc2626;
}

.status-indicator {
  font-weight: 600;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border-radius: 12px;
}

.open .status-indicator {
  background: #d1fae5;
}

.closed .status-indicator {
  background: #fee2e2;
}

/* Séparateurs entre les sections */
.shop-card__phone,
.shop-card__address,
.shop-card__hours-text {
  position: relative;
  border: 1px solid #f0f0f0;
}

/* Animation subtile au hover */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
}

.shop-card:hover {
  animation: pulse 1s ease-in-out;
}

@media (max-width: 768px) {
  .shops-container {
    padding: 6rem 1rem 1rem 1rem;
  }
  
  .shop-card__content {
    padding: 1.5rem;
  }
}

.shops-map-container {
  padding: 7rem 1.5rem 0 1.5rem;
  max-width: 800px;
  margin: 0 auto;
}

.map-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(0);
  -webkit-backdrop-filter: blur(0);
}

.modal-overlay.show {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.modal-container {
  width: 100%;
  max-width: 500px;
  opacity: 0;
  transform: scale(0.7) translateY(100px);
  transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1),
              opacity 0.4s ease-out;
}

.modal-container.show {
  opacity: 1;
  transform: scale(1) translateY(0);
}

/* Masquer les attributions de la carte */
.leaflet-control-attribution {
  display: none;
}

/* Masquer les contrôles de zoom */
.leaflet-control-zoom {
  display: none;
}

/* Support pour Safari */
@supports (-webkit-backdrop-filter: none) {
  .modal-overlay {
    -webkit-backdrop-filter: blur(0);
  }
  .modal-overlay.show {
    -webkit-backdrop-filter: blur(8px);
  }
}

.custom-icon {
  transition: all 0.3s ease-in-out;
}

.custom-icon:hover {
  transform: scale(1.2);
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
}

.custom-icon.selected {
  transform: scale(1.2);
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
} 