.desktop-install-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  min-height: 100vh;
  overflow-y: auto;
  padding: 2rem 1rem;
}

.desktop-install-content {
  width: 100%;
  max-width: 500px;
  text-align: center;
  padding: 2rem;
}

.desktop-install-logo {
  width: 100px;
  height: 100px;
  margin: 0 auto 1.5rem;
  border-radius: 20px;
}

.desktop-install-content h2 {
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  margin-bottom: 1rem;
  color: #333;
}

.desktop-install-content p {
  font-size: clamp(1rem, 2vw, 1.2rem);
  color: #666;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.qrcode-container {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 20px;
  display: inline-block;
  margin: 2rem auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.qrcode-image {
  width: 200px;
  height: 200px;
  display: block;
}

.install-steps {
  text-align: left;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 20px;
  width: 100%;
  max-width: 600px;
  margin: 1rem auto;
}

.install-steps p {
  font-size: clamp(1.1rem, 2vw, 1.3rem);
  font-weight: 600;
  margin-bottom: 1rem;
}

.install-steps ol {
  margin-top: 0.5rem;
  padding-left: 1.5rem;
}

.install-steps li {
  margin: 0.8rem 0;
  color: #666;
  font-size: clamp(0.9rem, 1.5vw, 1.1rem);
  line-height: 1.5;
}

/* Adaptation écrans moyens */
@media (max-height: 800px) {
  .desktop-install-prompt {
    align-items: flex-start;
  }
  
  .desktop-install-content {
    padding: 1rem;
    gap: 1rem;
  }
  
  .desktop-install-logo {
    width: 80px;
    height: 80px;
  }
  
  .qrcode-container {
    padding: 1rem;
  }
  
  .install-steps {
    padding: 1rem;
  }
}

/* Adaptation petits écrans */
@media (max-width: 480px) {
  .desktop-install-content {
    padding: 1rem 0.5rem;
  }
  
  .qrcode-image {
    width: 150px;
  }
  
  .install-steps {
    margin: 0.5rem auto;
  }
} 